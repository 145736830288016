import React from "react";
const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 365.6 39.3"
    >
      <path
        fill="#0047BB"
        d="M34.1 38.7h-8.2v-25l-18 25H0V.6h8.3v25L26.4.6h7.7zM40.1.7h8.4v15h16.8V.7h8.4v38h-8.4V23.4H48.5v15.3h-8.4zM88 38.7h-8.3V.6h25.8v7.6H88zM145.8 19.7c0-11.6-8.4-19.5-20-19.7h-.2c-11.6.1-20 8.1-20 19.6 0 11.6 8.4 19.5 20 19.6h.2c11.6 0 20-8 20-19.5zm-20.1 11.9c-6.7 0-11.4-4.6-11.4-11.9 0-7.4 4.7-11.9 11.4-11.9s11.4 4.6 11.4 11.9c0 7.3-4.7 11.9-11.4 11.9M233.3 8.4H221V.7h32.9v7.7h-12.2v30.3h-8.4zM259 .7h17.1c9.1 0 14.6 5.4 14.6 13.2 0 8.8-6.8 13.4-15.4 13.4h-7.9v11.4H259V.7zm16.5 19.1c4.2 0 6.6-2.5 6.6-5.9 0-3.7-2.6-5.8-6.8-5.8h-8v11.6h8.2zM365.6 38.7l-13.5-19.5L365 .6h-9.6l-8.1 11.7L339.2.6h-9.9l13 18.8-13.5 19.3h9.6l8.7-12.5 8.6 12.5zM319.4 38.7h8.5L311.6.4h-7.7l-16.3 38.3h8.5l3.6-8.8h16.1l3.6 8.8zm-16.7-16.2l5.1-11.9 5.1 11.9h-10.2zM169.5 31.6c-6.7 0-11.4-4.6-11.4-11.9 0-7.4 4.7-11.9 11.4-11.9 3.5 0 6.4 1 8.9 3.1l5.2-5.2c-3.5-3.4-8.2-5.6-14-5.6h-.2c-11.6.1-20 8.1-20 19.6 0 11.6 8.4 19.5 20 19.6h.2c5.7-.1 10.4-2.3 14-5.6l-5.2-5.2c-2.5 2.1-5.4 3.1-8.9 3.1M204.4 31.6c-6.7 0-11.4-4.6-11.4-11.9 0-7.4 4.7-11.9 11.4-11.9 3.5 0 6.4 1 8.9 3.1l5.2-5.2c-3.5-3.4-8.2-5.6-14-5.6h-.2c-11.6.1-20 8.1-20 19.6 0 11.6 8.4 19.5 20 19.6h.2c5.7-.1 10.4-2.3 14-5.6l-5.2-5.2c-2.5 2.1-5.4 3.1-8.9 3.1"
      ></path>
    </svg>
  );
};

export default Logo;
