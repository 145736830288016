const question1 = process.env.PUBLIC_URL + "/static/images/question1.png";
const question2 = process.env.PUBLIC_URL + "/static/images/question2.png";
const question3 = process.env.PUBLIC_URL + "/static/images/question3.png";
const question4 = process.env.PUBLIC_URL + "/static/images/question4.png";
const question5 = process.env.PUBLIC_URL + "/static/images/question5.png";
const question6 = process.env.PUBLIC_URL + "/static/images/question6.png";
const question7 = process.env.PUBLIC_URL + "/static/images/question7.png";
const question8 = process.env.PUBLIC_URL + "/static/images/question8.png";
const question9 = process.env.PUBLIC_URL + "/static/images/question9.png";
const question10 = process.env.PUBLIC_URL + "/static/images/question10.png";
const question11 = process.env.PUBLIC_URL + "/static/images/question11.png";

const introImage = process.env.PUBLIC_URL + "/static/images/intro.png";

const shareImage = process.env.PUBLIC_URL + "/static/images/shareImage.jpg";

const iphone = process.env.PUBLIC_URL + "/static/images/iphone-mockup.png";

export {
  question1,
  question2,
  question3,
  question4,
  question5,
  question6,
  question7,
  question8,
  question9,
  question10,
  question11,
  introImage,
  shareImage,
  iphone
};
